import React from "react";
import "./Retail.scss"
import AddressAutoComplete from "../AddressAutoComplete";
import Button from "../shared/Button";
import {getUserLocation} from "../../util/Util";
import {getConfigData, getStore} from "../../middleware";
import {saveCurrentLocation} from "../../actions/Locations";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {GoogleApiWrapper} from "google-maps-react";
import {useSelector} from "react-redux";

const Retail = () => {
  const {t} = useTranslation();
  const tr = t('home', {returnObjects: true});

  const formatted_address = useSelector(state => state.sharedData.formatted_address);

  const handleContinueClick = () => {
    window.location.pathname = '/locations';
  }

  const handleClickTrack = () => {
    console.log('handleClickTrack');
    getUserLocation().then();
  }

  const handleClickClear = () => {
    console.log('handleClickClear');
    getStore().dispatch({type: 'SAVE_CURRENT_LOCATION', data: {lat: '', lng: '', formatted_address: ''}});
  }

  const onPlaceLoaded = (address) => {
    try {
      let data = {
        lat: '',
        lng: '',
        formatted_address: '',
        address_components: []
      };
      if (JSON.parse(address) !== null) {
        let _address = JSON.parse(address);
        data = {
          lat: _address.geometry.location.lat,
          lng: _address.geometry.location.lng,
          formatted_address: _address.formatted_address,
          address_components: _address.address_components,
        };
      }
      getStore().dispatch(saveCurrentLocation(data));
    } catch (e) {
      console.log("Home::onPlaceLoaded error:", address, e);
    }
  }

  const isContinueDisabled = () => {
    console.log("isContinueDisabled:", formatted_address, (_.isEmpty(formatted_address)));
    return (_.isEmpty(formatted_address));
  }


  return (
    <>
      <div className="home-search">
        <div style={{height: "100%", display: "block"}}>
          <div className='title'>
            {tr.title}
          </div>
          <div className="subtitle">
            {tr.subtitle}
          </div>
          <div className="search-inputs">
            <div className="auto">
              <AddressAutoComplete
                onPlaceLoaded={address => onPlaceLoaded(address)}
                handleClickTrack={handleClickTrack}
                handleClickClear={handleClickClear}
                initialValue={formatted_address}
              />
            </div>
          </div>
            <Button cta={tr.buttons.continue} isDisabled={isContinueDisabled()}
                    button_type="primary"
                    handleClick={() => {
                      handleContinueClick()
                    }}/>
        </div>
      </div>
    </>
  );
}


const GoogleApiWrappedContainer = GoogleApiWrapper({
  apiKey: getConfigData().mapsApiKey
})(Retail);

export default GoogleApiWrappedContainer;

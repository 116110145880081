import React, {useEffect, useRef} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import './../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import {setWorkflow} from "../../util/workflow";
import config from "../../configs/oktaConfig";
// import {isFirstRun, setFirstRun} from "../../util/firstrun";
import {OktaAuth} from "@okta/okta-auth-js";
// import {Image, Popup} from "semantic-ui-react";
// import iconInfo from '../../assets/images/icon-info.svg';
// import {useTranslation} from "react-i18next";

import "./Login.scss";
import SvgWrapper from "../shared/SvgWrapper";

const WFLogin = () => {
  const {authState, oktaAuth} = useOktaAuth();
  // const [isTooltipOpen, setIsTooltipOpen] = useState(isFirstRun);
  const contextRef = useRef();

  // const {t} = useTranslation();
  // const tr = t('home', {returnObjects: true});

  useEffect(() => {
    setWorkflow('bi');

    if (authState && authState.isAuthenticated) {
      const basename = window.location.origin + '/';
      oktaAuth.signOut({postLogoutRedirectUri: basename});
    }
  }, [authState, oktaAuth]);

  const triggerLogin = async () => {
    const oktaCFG = config().oidc;
    const _oktaAuth = new OktaAuth(oktaCFG);
    await _oktaAuth.signInWithRedirect();
  };

  return (
    <>
      <div className='login-container'>
        <h1 className='head'>Welcome to the Western Union<sup>®</sup> Experience</h1>
        <p className='thanks'>
        Your opinion and feedback are important as we strive to improve our products and services.
          {/* Thank you for helping us provide the next level in service quality. <br/>Don't have a WesternUnion.com account? <a href="https://www.westernunion.com/us/en/home.html">Register now</a> */}
        </p>
        <button className='btn btn-primary login-cta' onClick={triggerLogin} style={{width: "89%"}}>Login</button>
        {/* <p className='register'>
          Don't have a WesternUnion.com account? <a href="https://www.westernunion.com/us/en/home.html">Register now</a>
        </p> */}
        <br/>
        <SvgWrapper src='static/login_bg.png' alt='login background' className='login-image' />
        
      </div>
      <footer>
          <p>
            <a href="https://www.westernunion.com/us/en/legal/terms-conditions.html">Terms & Conditions (US)</a>
            <br/>
            <a href="https://www.westernunion.com/global/en/privacy-statement.html">Online Privacy Statement</a>
          </p>
          <button className="ot-sdk-show-settings" href="#">Cookie Settings</button>
          <p className='copy'>&copy; 2024 Western Union, Inc. All rights reserved.</p>
        </footer>
      <div ref={contextRef} className="contextRef">&nbsp;</div>
      {/*<svg width="0" height="0" style={{position: 'absolute'}}>*/}
      {/*  <clipPath id="clipping">*/}
      {/*    <path width="375" height="262" fill-rule="evenodd" clip-rule="evenodd" d="M524.247 403L-131 403L72.701 49.2028C90.2292 18.7591 122.656 0 157.753 0L813 0L609.299 353.797C591.771 384.241 559.344 403 524.247 403Z" fill="#FEDD00"/>*/}
      {/*  </clipPath>*/}
      {/*</svg>*/}
    </>
  );
}

export default WFLogin;
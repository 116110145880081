import {call, fork, put, take, takeLatest} from "redux-saga/effects";
import {channel} from 'redux-saga'
import {getStore} from '../middleware/index';
import {getSamples} from "./Content";
import {isBusinessIntelligence, isDigital} from "../util/workflow";

const enabledLangs = [
  'en',
  'es'
];

export function* loadQuesitonsSaga() {
  yield takeLatest("LOAD_QUESTIONNARIES", loadQuesitonsWorkerSaga);
}

export function* loadQuesitonsWorkerSaga(_actions) {
  let store = getStore();
  const _in = channel();
  const _out = channel();
  let samples = [];
  yield fork(getSamples, _in, _out);

  try {
    for (let lang of enabledLangs) {
      yield put(_in, lang);
      samples[lang] = yield take(_out);
    }
    store.getState().questions = yield call(parseJSONStatic, samples);

    // while (store.getState().questions['en'].size <= 0) {
    //   yield delay(200);
    // }

    let serviceOptions = [];
    let oprkey = 1;
    const quest = store.getState().questions['en'];
    if (typeof (quest) === "undefined") {
      console.log("empty quest");
      return;
    }
    Object.keys(quest).forEach((key) => {
      let opkey = (key === "business-intelligence") ? 0 : oprkey;
      if (typeof quest[key] !== "undefined") {
        let text = (typeof quest[key]==="string") ? quest[key] : quest[key].get("title");
        serviceOptions.push({
          key: opkey,
          text: text,
          value: key,
        });
        oprkey++;
      }
    });

    // move BI to the first place
    const bi_index = serviceOptions.map(e => e.value).indexOf('business-intelligence');
    if (bi_index > 0) {
      const biElement = serviceOptions[bi_index - 1];
      serviceOptions.splice(bi_index, 1);
      serviceOptions.unshift(biElement);
    }

    yield put({type: "LOAD_QUESTIONNARIES_SUCCESS", options: serviceOptions});
  } catch (e) {
    console.log("Inside loadQuesitonsSaga error = ", e);
  }
}

export function parseJSONStatic(samples) {
  let result = {};

  for (let lang of enabledLangs) {
    let _keys = Object.keys(samples[lang]);
    result[lang] = parseJSONStaticNode(samples[lang]);
    if (_keys[0].toString() === "0") {
      if (typeof result === "undefined") {
        result[lang] = {};
      }
      for (let item of _keys) {
        if (isDigital()) {
          if (samples[lang][item].audience === "di") {
            result[lang][samples[lang][item].post_name] = parseJSONStaticNode(samples[lang][item]);
          }
        } else {
          if (samples[lang][item].audience !== "di") {
            result[lang][samples[lang][item].post_name] = parseJSONStaticNode(samples[lang][item]);
          }
        }
      }
    }
  }
  if (!isBusinessIntelligence()) {
    result.forEach((item) => {
      delete item["business-intelligence"];
    });
  }

  return result;
}

function parseJSONStaticNode(node) {
  let x = new Map();
  let spl;
  let newKey;

  Object.keys(node).map((key) => {
    if (key.startsWith('questions_')) {
      spl = key.split("_");
      newKey = spl.slice(2, spl.length).join("_");
      if (x.has(spl[1])) {
        let y = x.get(spl[1]);
        y.set(newKey.toString(), node[key]);
        x.set(spl[1], y);
      } else {
        let z = new Map();
        z.set("index", spl[1]);
        z.set("value", '');
        z.set("followup_reason", '');
        z.set("followup_text", '');
        z.set("followup_options", '0');
        z.set(newKey.toString(), node[key]);
        x.set(spl[1], z);
      }
    }
    if (key === "post_title") {
      x.set("title", node["post_title"]);
    }
    return x;
  });
  return x;
}
const QuestionsReducer = (state = {}, action={}) => {
  switch (action.type) {
    case "LOAD_QUESTIONNARIES_SUCCESS":
      return {...state, serviceOptions: action.options, quesitonsLoaded: true};
    case "LOAD_QUESTIONNARIES":
      return {...state, quesitonsLoaded: false};
    default:
      return state;
  }
}

export default QuestionsReducer;
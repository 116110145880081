import React, {Component} from "react";
import './LocationsRow.scss';
import {Form, Header, Modal} from "semantic-ui-react";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {getDirectionsUrl, getUSStatesSelectOptions} from "../../util/Util";
import {getStore} from "../../middleware";
import moment from "moment/moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../shared/Button";
import {withTranslation} from "react-i18next";

class LocationsRow extends Component {
  constructor(props) {
    super(props);
    const editable = (props.isEditable) ? props.isEditable : false;
    const now = moment();
    const markerDay = props.operatingHours.day.filter((item) => {
      return item.name === now.format("dddd");
    });
    const isOpen = (!markerDay[0].isClosed) ? now.isBetween(moment(markerDay[0].open, "HH:mm a"), moment(markerDay[0].close, "HH:mm a")) : false;
    const operating = markerDay[0].open + "-" + markerDay[0].close;
    this.state = {
      address: props.address,
      isEditable: editable,
      showModalEdit: false,
      isOpen: isOpen,
      operatingHours: operating
    };
  }

  setShowModal = (value) => {
    this.setState({showModalEdit: value});
  }

  getStateOptions = (selected) => {
    let options = [];
    getUSStatesSelectOptions().map((item) => {
      options.push({
        key: item.key,
        text: item.text,
        value: item.text,
        selected: (selected === item.value),
      });
      return true;
    });
    return options;
  }

  getModalHeader = () => {
    return (
      <div>
        <a href='.' onClick={() => this.setShowModal(false)} style={{display: 'block', width: '100%'}}>&lt; Back</a>
        <p>{this.props.stepsNames[this.props.currentStep - 1]}</p>
      </div>
    );
  }

  SaveAddress = () => {
    getStore().dispatch({type: "UPDATE_ADDRESS", address: this.state.address});
    this.setShowModal(false);
  }

  getOperatings = () => {
    if (this.state.isOpen) {
      return (
        <div>
          Open now: {this.state.operatingHours}
        </div>
      );
    }
    return (
      <div>Closed</div>
    );
  }

  render() {
    const {t} = this.props;
    const tr = t('locations', {returnObjects: true}).row;
    const details_url = "/location/details/key/" + this.props.dataKey;
    const addr = this.props.address.addrLine1 + ", "
      + this.props.address.city + ", " + this.props.address.state + ", " + this.props.address.postalCode;
    return (
      <div className="location-row">
        <img alt='street view' src={this.props.thumbnail}/>
        {(this.props.address) && (
          <a href={details_url} className='item-link'>
            <div className="row-details">
              <h3>{this.props.locationName.toLowerCase()}</h3>
              {(this.props.address)
                && (<p>
                  {this.props.address.addrLine1}<br/>
                  {this.props.address.city}, {this.props.address.state} {this.props.address.postalCode}
                </p>)}
              <div className="directions-row">
                <a className="directions" href={getDirectionsUrl(addr)} target="_blank"
                   rel="noreferrer">{tr.directions}</a> {this.getOperatings()}
              </div>
            </div>
          </a>
        )}
        {this.state.isEditable &&
          <div onClick={() => this.setShowModal(true)}><ModeEditOutlineIcon className='edit-address-icon'/></div>}
        <Modal
          basic
          id="update-address"
          centered={false}
          closeIcon
          open={this.state.showModalEdit}
          onClose={() => this.setShowModal(false)}
          onOpen={() => this.setShowModal(true)}
          size='fullscreen'
          className="update-address"
        >
          {(this.props.currentStep !== undefined && this.props.stepsNames) &&
            <Header className="update-address-modal-header">
              <div className="update-address-header">
                <a href='.' onClick={() => this.setShowModal(false)}
                   style={{display: 'block', width: '100%'}}><ChevronLeftIcon/> {tr.back}</a>
                <br/><br/>
                <p>{this.props.stepsNames[this.props.currentStep - 1]}</p>
              </div>
            </Header>}
          <Modal.Content className="update-address-content">
            <h1>{tr.updateAddres.title}</h1>
            <p>{tr.updateAddres.subtitle}</p>
            <Form>
              <Form.Input className="input-field-input" fluid label='Address Line 1' placeholder='Address Line 1'
                          value={this.state.address.addrLine1}/>
              <Form.Input className="input-field-input" fluid label='Address Line 2' placeholder='Address Line 2'
                          value={this.state.address.addrLine2}/>
              <Form.Input className="input-field-input" fluid label='City' placeholder='City'
                          value={this.state.address.city}/>
              <Form.Group widths='equal'>
                <Form.Select
                  className="input-field-select"
                  fluid
                  label={tr.updateAddres.form.state}
                  options={this.getStateOptions(this.state.address.state)}
                  placeholder={'State'}
                  defaultValue={this.state.address.state}
                />
                <Form.Input className="input-field-input" fluid label={tr.updateAddres.form.zipCode}
                            placeholder={tr.updateAddres.form.zipCode} value={this.state.address.postalCode}/>
              </Form.Group>

              <Form.Input className="input-field-input" fluid label={tr.updateAddres.form.tel}
                          placeholder={tr.updateAddres.form.tel} value={this.state.address.contactPhone}/>
              <div className='action-box'>
                <Button className='save-hours' cta={tr.updateAddres.form.save} button_type="primary"
                        handleClick={this.SaveAddress}/>
              </div>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(LocationsRow);

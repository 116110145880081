import React, {createContext, useEffect, useReducer, useState} from "react";
import StepWizard from "react-step-wizard";
import AddPhotos from "./AddPhotos";
import VerifyAddressHours from "./VerifyAddressHours";
import Questionnaires from "./Questionnaires";
import Review from "./Review";
import Nav from "./Nav";
import {useStore} from "react-redux";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import FeedbackReducer from "../../reducers/FeedbackReducer";

import "./LocationFeedback.scss";

export const MainFeedbackContext = createContext({});

const LocationFeedback = ({getServiceType, agent}) => {
  const [serviceType] = useState(getServiceType());
  const store = useStore();
  const address = store.getState().feedback.address;
  const locationName = store.getState().feedback.locationName;
  const dataKey = store.getState().feedback.key;
  const {t} = useTranslation();
  const tr = t('feedback', {returnObjects: true});
  const stepsNames = () => {
    let steps = tr.steps;
    if (isBI()) {
      steps = [];
      for (const positions of [2, 0, 3]) {
        steps.push(tr.steps[positions]);
      }
    }

    return steps;
  }

  const [review, setReview] = useReducer(FeedbackReducer, store.getState().feedback);

  useEffect(() => {
    store.getState().agentDataMap.showNotification = false;
  });

  const getServiceTypeSelected = () => {
    return serviceType;
  }

  const isBI = () => {
    return getServiceTypeSelected() === "business-intelligence";
  }

  return (
    <MainFeedbackContext.Provider value={{review, setReview}}>
      <div className="location-feedback">
        <StepWizard key={uniqid()} initialStep={1} isHashEnabled={true} nav={<Nav stepsNames={stepsNames()} isBI={isBI} />}>
          {!isBI() &&
            <AddPhotos key={uniqid()} isFeedback={true} isBI={isBI} />
          }
          {!isBI() &&
            <VerifyAddressHours address={address} key={uniqid()} locationName={locationName}/>
          }
          <Questionnaires key={uniqid()}
                          getServiceType={getServiceTypeSelected} isBI={isBI}
          />
          {isBI() &&
            <AddPhotos key={uniqid()} isFeedback={true} isBI={isBI} />
          }
          <Review dataKey={dataKey} locationName={locationName}
                  key={uniqid()} getServiceType={getServiceTypeSelected} isBI={isBI} agent={agent} />
        </StepWizard>
      </div>
    </MainFeedbackContext.Provider>
  );
}

export default LocationFeedback;

import React, {Component} from "react";
import {connect} from "react-redux";
import LocationsRow from "../LocationsRow";
import {Dropdown, Modal} from 'semantic-ui-react'
import UpdateHours from "../UpdateHours";
import LocationFeedback from "../LocationFeedback";
import {getStore} from "../../middleware";
import {getStreetViewUrl} from "../../util/Util";
import Button from "../shared/Button";
import {withTranslation} from "react-i18next";
import './LocationDetails.scss';

class LocationDetails extends Component {
  constructor(props) {
    super(props);
    const key = props[0].match.params.key;
    const agent = this.props.agents.find(_agent => {
      return _agent.key === key
    });

    const disableBI = true;

    let coords = getStore().getState().sharedData;
    const review = {
      key: key,
      locationName: agent.rowData.name,
      address: {...agent.rowData.address, contact: agent.rowData.contactPhone},
      operatingHours: agent.rowData.operatingHours,
      photos: [],
      type: '',
      coordinates: {
        lat: coords.lat,
        lng: coords.lng,
      },
      questions: [],
    };
    getStore().dispatch({type: "INIT_REVIEW", data: review});

    let serviceOptions = this.props.serviceOptions;
    if (disableBI) {
      serviceOptions = serviceOptions.filter(item => {
        return item.value !== "business-intelligence";
      })
    }

    this.state = {
      key: key,
      agent: agent,
      showModalReview: false,
      showModalHours: false,
      service_type: "",//serviceOptions[0].value,
      services_options: serviceOptions,
    }
  }

  setShowModal(modal, showModal) {

    (modal === "review")
      ? this.setState({showModalReview: showModal})
      : this.setState({showModalHours: showModal})
    ;
  }

  renderHoursLink = () => {
    return (
      <svg width="403" height="161" viewBox="0 0 403 161" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter x="-15.5%" y="-53.8%" width="131%" height="211.8%"
                  filterUnits="objectBoundingBox" id="a">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="17" in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"/>
            <feColorMatrix
              values="0 0 0 0 0.152941176 0 0 0 0 0.203921569 0 0 0 0 0.490196078 0 0 0 0.17 0"
              in="shadowBlurOuter1"/>
          </filter>
          <path
            d="M12 0h311c6.627 0 12 5.373 12 12v69c0 6.627-5.373 12-12 12H12C5.373 93 0 87.627 0 81V12C0 5.373 5.373 0 12 0z"
            id="b"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(34 32)">
            <use fill="#000" filter="url(#a)"/>
            <use fill="#FFF"/>
          </g>
          <path
            d="M78.5 63C87.06 63 94 70.163 94 79s-6.94 16-15.5 16C69.94 95 63 87.837 63 79s6.94-16 15.5-16zm1.938 14.5h-3.876V88h3.876V77.5zM78.5 70.15a2.324 2.324 0 0 0-.969.2 2.868 2.868 0 0 0-.784.52 2.326 2.326 0 0 0-.703 1.7c-.01.648.246 1.27.703 1.715.466.473 1.099.73 1.753.715.331.003.66-.06.969-.185a2.364 2.364 0 0 0 1.293-1.28c.131-.302.197-.63.194-.96a2.334 2.334 0 0 0-.18-.955 2.363 2.363 0 0 0-1.307-1.28 2.412 2.412 0 0 0-.969-.19zM346 79l-8.493 8L336 85.512 343 79l-7-6.512L337.507 71z"
            fill="#2D4E9D"/>

        </g>
      </svg>);
  }

  handleServiceTypeSelect = (e, {value}) => {
    e.preventDefault();
    this.setState({service_type: value});
  }

  handelUpdateHours = (value) => {
    let review = getStore().getState().feedback;
    review.operatingHours = value;
    getStore().dispatch({type: "UPDATE_HOURS", operatingHours: review.operatingHours});
  }

  getServiceType = () => {
    return this.state.service_type;
  }

  render() {
    const {t} = this.props;
    const tr = t('locations', {returnObjects: true}).details;
    const currentAddress = this.state.agent.rowData.address.addrLine1 + ", "
      + this.state.agent.rowData.address.city + ", " + this.state.agent.rowData.address.state + ", "
      + this.state.agent.rowData.address.postalCode;
    return (
      <div className='location-details'>
        <a href='/locations' className='back-link'>Back</a>
        <img alt='street view' src={getStreetViewUrl(currentAddress)}/>
        <div className="location-details-container">

          <div className='review-type-popup'>
            <div>
              <div>
                <LocationsRow
                  address={{
                    ...this.state.agent.rowData.address,
                    contactPhone: this.state.agent.rowData.contactPhone
                  }}
                  key={this.state.key} dataKey={this.state.key}
                  locationName={this.state.agent.rowData.name}
                  operatingHours={this.state.agent.rowData.operatingHours}
                  showStreetView={'false'}
                  thumbnail={getStreetViewUrl(currentAddress)}
                />
              </div>
            </div>


            <h2>{tr.title}</h2>
            <Dropdown
              id="review-type-selector"
              fluid
              selection
              placeholder='Select your retail experience'
              value={this.state.service_type}
              options={this.state.services_options}
              onChange={this.handleServiceTypeSelect}
            />

            <Button handleClick={() => {
              this.setShowModal("review", true)
            }}
                    isDisabled={this.getServiceType() === ""}
                    cta="Start Feedback"
                    button_type="primary"/>
            <Modal
              style={{left: "auto !important"}}
              id="review-modal"
              basic
              centered={false}
              closeIcon
              open={this.state.showModalReview}
              onClose={() => this.setShowModal("review", false)}
              onOpen={() => this.setShowModal("review", true)}
              size='fullscreen'
            >
              <Modal.Content>
                <LocationFeedback getServiceType={this.getServiceType} agent={this.state.agent}/>
              </Modal.Content>
            </Modal>
          </div>
          <div className='review-hours'>
            <div className='hours-row'>
              <button
                className="btn-location-update-hours"
                onClick={() => {
                  this.setShowModal("hours", true)
                }}>
                <div className="info-icon"></div>
                <div className="review-hours-copy">
                  <p className="review-hours-question">Is this store closed?</p>
                  <p className="review-hours-CTA">Update hours</p>
                </div>
                <div className="arrow-left"></div>
              </button>
            </div>

            <Modal
              id="review-hours-modal"
              style={{left:"auto !important"}}
              basic
              centered={false}
              closeIcon
              open={this.state.showModalHours}
              onClose={() => this.setShowModal("hours", false)}
              onOpen={() => this.setShowModal("hours", true)}
              size='fullscreen'
            >
              <Modal.Content>
                <UpdateHours data={this.state.agent.rowData.operatingHours} handleUpdate={this.handelUpdateHours}
                             continueAction={() => this.setShowModal("hours", false)}
                             isFeedback={false}
                />
              </Modal.Content>
            </Modal>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    agents: state.agentDataMap.agents,
    serviceOptions: state.questions.serviceOptions,
  };
};

export default connect(mapStateToProps)(withTranslation()(LocationDetails));

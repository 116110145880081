import React, {useEffect, useRef, useState} from "react";
import "./Home.scss";
import Button from "../shared/Button";
import {getOktaAuth, setFlowAudience} from "../../util/workflow";
import {Image, Popup} from "semantic-ui-react";
import iconInfo from "../../assets/images/icon-info.svg";
import {useTranslation} from "react-i18next";
import {isFirstRun, setFirstRun} from "../../util/firstrun";
import {loadQuestionnaires} from "../../util/Util";

const Home = () => {
  const [oktaAuth] = useState(getOktaAuth());
  const [username, setUsername] = useState("");

  const {t} = useTranslation();
  const tr = t('home', {returnObjects: true});

  const [isTooltipOpen, setTooltipOpen] = useState(isFirstRun());

  const contextRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      const profile = info;
      const userName = (profile) ? profile.given_name.toUpperCase() : '';
      setUsername(userName);
    }).catch((err) => {
      console.error(err);
    });
    loadQuestionnaires();
  }, [setUsername, oktaAuth]);


  const getTargetUrl = (isDigital) => {
    return (isDigital) ? "/digital/review" : "/retail";
  }

  const handleContinueClick = (flow) => {
    const isDigital = (flow==="digital");
    if (isDigital) {
      setFlowAudience("di");
    } else {
      setFlowAudience("wu");
    }
    window.location.pathname = getTargetUrl(isDigital);
  }

  const getContent = (translation) => {
    const delimiter = "icon";
    const popContent = translation.popup.split(delimiter);

    return (
      <>
        {popContent[0]}
        &nbsp;
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.6 7.61063V2.00123L7.27573 2.66048C7.50475 2.88392 7.88603 2.88526 8.12035 2.65667C8.3563 2.42647 8.35641 2.05914 8.12426 1.83265L6.41938 0.169351C6.30551 0.0582548 6.15399 0.00206468 6.00161 0.00174754L5.99703 0C5.84524 0.000138743 5.69411 0.0567314 5.57868 0.169351L3.8738 1.83265C3.64478 2.05608 3.64339 2.42807 3.87771 2.65667C4.11366 2.88686 4.49017 2.88697 4.72232 2.66048L5.4 1.99933V7.61063C5.4 7.93318 5.66863 8.19512 6 8.19512C6.33368 8.19512 6.6 7.93344 6.6 7.61063ZM10.8 6.72901V10.8293H1.2V6.72901C1.2 6.40923 0.931371 6.14634 0.6 6.14634C0.266319 6.14634 0 6.40721 0 6.72901V11.4173C0 11.5771 0.0670476 11.7226 0.176396 11.8303C0.284756 11.9354 0.434183 12 0.599187 12H11.4008C11.5662 12 11.7156 11.9348 11.8254 11.8291C11.9335 11.7237 12 11.5781 12 11.4173V6.72901C12 6.40923 11.7314 6.14634 11.4 6.14634C11.0663 6.14634 10.8 6.40721 10.8 6.72901Z"
                fill="black"/>
        </svg>
        &nbsp;
        {delimiter}
        {popContent[1]}
      </>
    )
  }

  return (
    <>
      <div className="home-search">
        <div className={(isTooltipOpen) ? 'mask' : 'mask disabled'}>
        </div>
        <div style={{height: "100%", display: "block"}}>
          <div className='title'>
            Welcome, {username}
          </div>
          <div className="subtitle">
            Select the experience about which you will be providing feedback:
          </div>
          <div ref={tooltipRef} style={{display: "block", marginTop: 10}}>
            <div style={{display: "block", marginTop: 10}}>
              <Button cta="Retail"
                      button_type="primary"
                      handleClick={() => {
                        handleContinueClick('retail')
                      }}/>
            </div>
            <div style={{display: "block", marginTop: 10}}>
              <Button cta="Digital"
                      button_type="primary"
                      handleClick={() => {
                        handleContinueClick('digital')
                      }}/>
            </div>
          </div>
          <Popup
            closeIcon
            open={isTooltipOpen}
            onClose={() => {
              setFirstRun(false);
              setTooltipOpen(false);
            }}
            onOpen={() => {
              setFirstRun(false);
              setTooltipOpen(true);
            }}
            pinned
            dimmer
            position="top center"
            context={contextRef}
            closable={true}
          >
            <Popup.Content>
              <div className='icon-info'>
                <Image size='tiny' style={{margin: '0 auto 12px', width: '40px', height: '40px'}} src={iconInfo}/>
                <div className="close-popup" onClick={() => {
                  setFirstRun(false);
                  setTooltipOpen(false);
                }}>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.75666 11.0265L16.8404 17.6935C17.3075 18.1331 18.0393 18.122 18.4929 17.6685C18.9683 17.1931 18.9542 16.4181 18.4619 15.9603L11.5152 9.5L18.4619 3.03968C18.9542 2.58186 18.9683 1.8069 18.4929 1.33153C18.0393 0.877984 17.3075 0.866898 16.8404 1.3065L9.75666 7.97355L2.67292 1.3065C2.20584 0.866898 1.47399 0.877984 1.02044 1.33153C0.545064 1.8069 0.559115 2.58186 1.05141 3.03968L7.99807 9.5L1.05141 15.9603C0.559116 16.4181 0.545064 17.1931 1.02044 17.6685C1.47399 18.122 2.20584 18.1331 2.67292 17.6935L9.75666 11.0265Z"
                          fill="#FFDD00"/>
                  </svg>
                </div>
              </div>
              <p className="popupContent">
                {getContent(tr)}
              </p>
            </Popup.Content>
          </Popup>
          <div ref={contextRef} className="contextRef">&nbsp;</div>
        </div>
      </div>
    </>
  );
}

export default Home;

import _ from "lodash";
import config from "../configs/oktaConfig";
import {OktaAuth} from "@okta/okta-auth-js";

/* From WUE-12,
Flows:
“wu” (Customer) – the default. Shown to regular and BI users in the location review select list
“bi” (Employee) – added to the list for Workforce users (add at top of list)

Audiences:
“di” (Digital) – Shown to all users when they click the “review a digital transaction” CTA
“ms” (Mystery Shopper) – not currently  used. This will replace the list of questionnaires when reviewing a location (if we ever implement this user flow)

 */


// Flows
const getWorkflow = () => {
  let wf = localStorage.getItem("wu-workflow");
  if (_.isEmpty(wf)) {
    wf = "wu";
    setWorkflow(wf);
  }
  return wf;
}

const setWorkflow = (wf) => {
  localStorage.setItem("wu-workflow", wf);
}

const isBusinessIntelligence = () => {
  return (getWorkflow() === "bi");
}

// Audiences
const getFlowAudience = () => {
  let wfa = localStorage.getItem("wu-workflow-audience");
  if (_.isEmpty(wfa)) {
    wfa = "wu";
    setFlowAudience(wfa);
  }
  return wfa;
}

const setFlowAudience = (wfa) => {
  localStorage.setItem("wu-workflow-audience", wfa);
}

const isDigital = () => {
  return (getFlowAudience() === "di");
}

const getOktaAuth = () => {
  const oktaCFG = config().oidc;
  return new OktaAuth(oktaCFG);
}

export {getWorkflow, setWorkflow, isBusinessIntelligence, getFlowAudience, setFlowAudience, isDigital, getOktaAuth}
import React, {Component} from "react";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import {getConfigData, getStore} from "../../middleware";
import {loadAgents, loadSearchAgents, saveCurrentLocation, updateCurrentLocation} from "../../actions/Locations";
import {connect} from "react-redux";
import LocationsRow from "../LocationsRow";
import AddressAutoComplete from '../AddressAutoComplete/AddressAutoComplete';
import _ from 'lodash';

import "./Locations.scss";
import {geocodeCoordinates, getDirectionsUrl, getStreetViewUrl, getUserLocation} from "../../util/Util";
import SwitchButton from "../shared/SwitchButton";
import moment from "moment/moment";
import iconInfo from "../../assets/images/icon-info.svg";
import {withTranslation} from "react-i18next";
import {getCurrentBrand} from "../../util/brandselector";
import {Image, Popup} from "semantic-ui-react";

class Locations extends Component {

  constructor(props) {
    super(props);
    const mode = props.t('locations', {returnObjects: true}).switchButton.ctaleft;
    this.state = {
      mode: mode,
      showingInfoWindow: false,
      activeMarker: {},
      activeMarkerAddress: "",
      activeMarkerUrl: '',
      activeMarkerOpen: false,
      activeMarkerOperating: '',
      selectedPlace: {},
      lat: props.lat,
      lng: props.lng
    }
    this.refAddrMap = React.createRef();
    this.onClickMode = this.onClickMode.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.agents)) {
      this.props.loadAgents();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.lat !== this.props.lat) || (prevProps.lng !== this.props.lng)) {
      this.props.loadAgents();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let newState = this.state;
    for (const nextPropsKey in nextProps) {
      if (nextProps[nextPropsKey] !== newState[nextPropsKey]) {
        newState[nextPropsKey] = nextProps[nextPropsKey];
      }
    }
    this.setState(newState);
  }

  getCenter = (_mapProps, map) => {
    const location = {lat: map.center.lat(), lng: map.center.lng()};
    geocodeCoordinates(location.lat, location.lng);
    this.setState(location);
    getStore().dispatch(loadSearchAgents(location));
  };

  onPlaceLoaded(address) {
    try {
      let data = {
        lat: '',
        lng: '',
        formatted_address: ''
      };
      if (JSON.parse(address) !== null) {
        let _address = JSON.parse(address);
        data = {
          lat: _address.geometry.location.lat,
          lng: _address.geometry.location.lng,
          formatted_address: _address.formatted_address
        };
      }
      getStore().dispatch(saveCurrentLocation(data));
      this.props.loadAgents();
    } catch (e) {
      console.error("Locations::onPlaceLoaded error:", address, e);
    }
  }

  handleClickClear = () => {
    console.log('handleClickClear');
    getStore().dispatch({type: 'SAVE_CURRENT_LOCATION', data: {lat: '', lng: '', formatted_address: ''}});
  }

  handleClickTrack = () => {
    console.log('handleClickTrack');
    getUserLocation().then((response) => {
      const data = response.data;
      getStore().dispatch(updateCurrentLocation(data));
      getStore().dispatch(loadSearchAgents(data));
      saveCurrentLocation(data);
      this.props.loadAgents();
    });
  }

  onClickMode = (data) => {
    this.setState({mode: data});
  }

  onMarkerClick = (props, marker, _e) => {
    const now = moment();
    const markerDay = marker.data.operatingHours.day.filter((item) => {
      return item.name === now.format("dddd");
    });
    const isOpen = (!markerDay[0].isClosed) ? now.isBetween(moment(markerDay[0].open, "HH:mm a"), moment(markerDay[0].close, "HH:mm a")) : false;
    const operating = markerDay[0].open + "-" + markerDay[0].close;
    const url = "/location/details/key/" + marker.data.key;
    const _activeMarkerAddress = marker.data.address.addrLine1 + ", "
      + marker.data.address.city + ", " + marker.data.address.state + ", " + marker.data.address.postalCode;
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      activeMarkerAddress: _activeMarkerAddress,
      activeMarkerUrl: url,
      activeMarkerOpen: isOpen,
      activeMarkerOperating: operating,
      showingInfoWindow: true
    });
  }

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        activeMarkerUrl: null,
      })
    }
  };

  renderSwitchMode = () => {
    const {t} = this.props;
    const tr = t('locations', {returnObjects: true}).switchButton;
    return (
      <SwitchButton cta_left={tr.ctaleft} cta_right={tr.ctaright} defaultValue={this.state.mode}
                    toggleFunction={(data) => this.onClickMode(data)}/>
    );
  }

  renderMarker = (data) => {
    return (
      <Marker
        onClick={this.onMarkerClick}
        name={data.marker.id}
        id={data.marker.id}
        key={data.marker.key}
        position={{lat: data.marker.coordinates.latitude, lng: data.marker.coordinates.longitude}}
        title={data.marker.name}
        label={data.rowData.id + 1 + ""}
        data={data.rowData}
        draggable={false}
      />
    );
  }

  getOperatings = () => {
    if (this.state.activeMarkerOpen) {
      return (
        <div style={{'textAlign': 'left'}}>
          <Image size='tiny' style={{width: '14px', height: '14px', display:'inline'}} src={iconInfo} /> Open
          now: {this.state.activeMarkerOperating}
        </div>
      );
    } else {
      return (
        <div style={{'textAlign': 'left'}}>
          <Image size='tiny' style={{width: '14px', height: '14px', display: 'inline'}} src={iconInfo} /> Closed
        </div>
      )
    }

  }

  renderMap = (tr) => {
    const containerStyle = {
      position: 'absolute',
      top: '128px',
      left: '0',
      width: '100%',
      height: '100%'
    };
    const fmt_address = ((this.props.formatted_address === "") || (typeof this.props.formatted_address === "undefined"))
      ? ""
      : this.props.formatted_address;

    const location = {lat: this.state.lat, lng: this.state.lng};

    return (
      <div>
        <div className="location-container-map">
          <Map google={this.props.google}
               zoom={14}
               zoomEnabled={true}
               initialCenter={location}
               center={location}
               onDragend={this.getCenter}
               fullscreenControl={false}
               mapTypeControl={false}
               streetViewControl={false}
               zoomControl={false}
               containerStyle={containerStyle}
          >
            <div>
                <AddressAutoComplete
                  onPlaceLoaded={address => this.onPlaceLoaded(address)}
                  handleClickTrack={this.handleClickTrack}
                  handleClickClear={this.handleClickClear}
                  initialValue={fmt_address}
                />
              <div ref={this.refAddrMap} style={{display: "block", height: "77px", width: "100%"}}>
              </div>
              {_.isEmpty(fmt_address) &&
                <Popup
                  content={tr.enterLocation}
                  context={this.refAddrMap}
                  open
                  position='bottom center'
                />
              }
            </div>
            {this.props.agents != null &&
              this.props.agents.map(agent => {
                if (agent && agent.marker) {
                  return this.renderMarker(agent);
                } else {
                  return null;
                }
              })}
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}>
              {this.state.activeMarker && (
                <div className="infowindow">
                  <div className="infowindow-box">
                    <div className="infowindow-data">
                        <a href={this.state.activeMarkerUrl} className='infowindow-link'>
                        <img alt="street view"
                             src={getStreetViewUrl(this.state.activeMarkerAddress)}
                             id={"stv_" + this.state.activeMarker.id}
                        />
                        <h2 className="title">{this.state.activeMarker.title}</h2>
                        {(this.state.activeMarker.data && this.state.activeMarker.data.address) && (<p>
                          {this.state.activeMarker.data.address.addrLine1}<br/>
                          {this.state.activeMarker.data.address.city}, {this.state.activeMarker.data.address.state} {this.state.activeMarker.data.address.postalCode}
                        </p>)}
                      </a>
                    </div>
                    <div className="infowindow-directions">
                      <a className="directions" href={getDirectionsUrl(this.state.activeMarkerAddress)} target="_blank"
                         rel="noreferrer">{tr.directions}</a>
                    </div>
                    <div className="infowindow-operations">
                      <div>{this.getOperatings()}</div>
                    </div>
                  </div>
                </div>
              )}
            </InfoWindow>
            <div className="banner">
              <div className="rectangle3">
                <div className="text">Tap on a location on the map to view details</div>
              </div>
            </div>
            <div className="switch-views">
              {this.renderSwitchMode(tr.switchButton.ctaleft)}
            </div>
          </Map>
        </div>
      </div>
    );
  }

  renderList = (tr) => {
    const fmt_address = ((this.props.formatted_address === "") || (typeof this.props.formatted_address === "undefined"))
      ? ""
      : this.props.formatted_address;
    console.log("brandName",tr);
    const brandName = tr.brandName[getCurrentBrand()];
    const caption = tr.title.replace("%BRAND%", brandName);
    const title = (fmt_address==="")
      ? tr.enterLocation
      : caption + " " + getStore().getState().sharedData.formatted_address;
    return (
      <div className="location-list">
        <div style={{display: "block", padding: "0 5% 0"}}>
          <AddressAutoComplete
            onPlaceLoaded={address => this.onPlaceLoaded(address)}
            handleClickTrack={this.handleClickTrack}
            handleClickClear={this.handleClickClear}
            initialValue={fmt_address}
          />
        </div>
        <br/>
        <h1 className="title">{title}</h1>
        <div>
          {this.renderSwitchMode()}
        </div>
        <ul>
          {this.props.agents != null &&
            this.props.agents.map(agent => {
              if (agent) {
                const currentAddress = agent.rowData.address.addrLine1 + ", "
                  + agent.rowData.address.city + ", " + agent.rowData.address.state + ", "
                  + agent.rowData.address.postalCode;
                return (
                  <li>
                    <LocationsRow
                      address={{...agent.rowData.address, contactPhone: agent.rowData.contactPhone}}
                      dataKey={agent.rowData.key} key={agent.rowData.key}
                      locationName={agent.rowData.name}
                      operatingHours={agent.rowData.operatingHours}
                      thumbnail={getStreetViewUrl(currentAddress)}
                    />
                  </li>
                );
              } else {
                return null;
              }
            })}
        </ul>
      </div>
    );
  }

  render() {
    const {t} = this.props;
    const tr = t('locations', {returnObjects: true});
    const mode = tr.switchButton.ctaleft;
    return (
      <div className="locations-container">
        {this.state.mode === mode
          ? this.renderMap(tr)
          : this.renderList(tr)
        }
      </div>
    );
  }
}

const GoogleApiWrappedContainer = GoogleApiWrapper({
  apiKey: getConfigData().mapsApiKey
})(Locations);

const mapStateToProps = state => {
  return {
    agents: state.agentDataMap.agents,
    isLoading: state.agentDataMap.isLoading,
    lat: state.sharedData.lat,
    lng: state.sharedData.lng,
    formatted_address: state.sharedData.formatted_address,
    isMarkerTooltipOpen: false,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadAgents: () => dispatch(loadAgents())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GoogleApiWrappedContainer));


import React, {useContext, useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector, useStore} from "react-redux";
import './DigitalQuestionnaires.scss';
import {Boolean, Input, Rating, Scale, Select} from "../../shared/Fields";
import {useTranslation} from "react-i18next";
import {Oval} from "react-loader-spinner";
import _ from "lodash";
import uniqid from 'uniqid';
import Button from "../../shared/Button";
import {DigitalFeedbackContext} from "../DigitalFeedback";
import SubmissionModel from "../../../helpers/SubmissionModel";
import {Modal} from "semantic-ui-react";

const successContent = (setShowNotification) => {
  return (
    <>
      <h1>Congratulations!</h1>
      <p>You have successfully submitted your feedback</p>

      <div className="action-box">
        <Button cta="Return Home" button_type="primary"
                handleClick={(event) => {
                  setShowNotification(false);
                  window.location.href = '/';
                }}/>
      </div>
    </>
  );
}

const failContent = (setShowNotification, notificationMsg, setReview) => {
  return (
    <>
      <h1>Something went wrong!</h1>
      <p>{notificationMsg}</p>

      <div className="action-box">
        <Button cta="Close" button_type="primary"
                handleClick={(event) => {
                  setReview({});
                  window.location.href = '/';
                  setShowNotification(false);
                }}/>
      </div>
    </>
  );
}

const renderModal = (isLoading, hasSuccess, currentAddress, locationName, address, showNotification, setShowNotification, notificationMsg, setReview) => {
  return (<Modal
    open={showNotification}
    dimmer="blurring"
    size="small"
    className="congrats"
  >
    <Modal.Content>
      {isLoading &&
        <Oval className="loader" heigth="100" width="100" color='yellow' ariaLabel='loading'/>}
      {!isLoading && ((true === hasSuccess) ? successContent(setShowNotification) : failContent(setShowNotification, notificationMsg, setReview))}
    </Modal.Content>
  </Modal>);
}

const DigitalQuestionnaires = ({getServiceType}) => {
  const {review, setReview} = useContext(DigitalFeedbackContext);

  const defaultServiceType = "digital-send-money";
  const store = useStore();
  const {t} = useTranslation();
  const tr = t('questions', {returnObjects: true});
  const trreview = t('review', {returnObjects: true});

  const _lang = useSelector((state) => state.sharedData.language);
  const lang = (!_.isEmpty(_lang)) ? _lang : 'en';
  const serviceType = (typeof getServiceType() === "undefined")
    ? defaultServiceType
    : getServiceType();
  const questions = useSelector((state) => state.questions, shallowEqual);

  const [fields, setFields] = useState(review.questions);

  const fieldRef = useRef(null);

  const [showNotification, setShowNotification] = useState(false);
  let hasSuccess, isLoading, notificationMsg;
  hasSuccess = useSelector((state) => state.agentDataMap.isAgentDetailSavedSuccess);
  isLoading = useSelector((state) => state.agentDataMap.loading);
  notificationMsg = useSelector((state) => state.agentDataMap.notificationMsg);

  useEffect(() => {
    if (questions.quesitonsLoaded) {

      fieldRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [ questions, fields, setFields, store, lang, serviceType]);


  const updateResponse = (index, value, followup, type) => {
    const _fields = fields;
    _fields[index].response = value;
    if (type === "radio") {
      _fields[index].followup_reason = followup.radio;
    } else {
      _fields[index].followup_text = followup.text;
    }
    setFields(_fields);
  }

  const renderItems = (_questions) => {
    //
    if (_.isEmpty(fields)) {
      const qrs = [];
      const list = questions[lang][serviceType];
      if (!_.isEmpty(list)) {
        let index = 1;
        list.forEach((value) => {
          if (typeof  value !== "string") {
            let has_followup = (value.get('ask_followup') !== "none");
            let reason = (value.get('followup_reason')) ? value.get('followup_reason') : '';
            let followupText = (value.get('followup_text')) ? value.get('followup_text') : '';
            let z = {
              index: index,
              field_type: value.get('question_type'),
              question: value.get('question_text'),
              response: value.get('value'),
              has_followup: has_followup,
              followup_question: value.get('followup_question'),
              followup_reason: reason,
              followup_text: followupText
            };
            qrs.push(z);
            index++;
          }
        });
        setFields(qrs);
      }
    }
    //
    let item = [];
    let order = 1;
    const fnc = updateResponse;
    if (typeof _questions[lang] !== "undefined" && typeof fields !== "undefined") {
      _questions[lang][serviceType].forEach((value, index) => {
        if (typeof  value !== "string") {
          const key = uniqid();
          const defaultVal = (!_.isEmpty(fields[index])) ? fields[index].response : "";
          const question_type = value.get("question_type");
          const question_subtype = value.get("is_input_numeric");

          const followup_value = {radio: "", text: ""};
          if (typeof review.questions[index] !== "undefined") {
            followup_value.radio = review.questions[index].followup_reason;
            followup_value.text = review.questions[index].followup_text;
          }
          switch (question_type) {
            case "select":
              item.push(
                <>
                  <Select settings={value} order={order} index={index} handleChange={fnc} key={key}
                          defaultValue={defaultVal} followupValue={followup_value}/>
                </>
              );
              break;
            case "boolean":
              item.push(
                <>
                  <Boolean settings={value} order={order} index={index} handleChange={fnc} key={key}
                           defaultValue={defaultVal} followupValue={followup_value}/>
                </>
              );
              break;
            case "rating":
              item.push(
                <>
                  <Rating settings={value} order={order} index={index} handleChange={fnc} key={key}
                          defaultValue={defaultVal} followupValue={followup_value}/>
                </>
              );
              break;
            case "scale":
              item.push(
                <>
                  <Scale settings={value} order={order} index={index} handleChange={fnc} key={key}
                         defaultValue={defaultVal} followupValue={followup_value}/>
                </>
              );
              break;
            default:
              item.push(
                <>
                  <Input settings={value} order={order} index={index} handleChange={fnc} key={key}
                         followupValue={followup_value} subtype={question_subtype}
                         defaultValue={defaultVal}/>
                </>
              )
          }
          order++;
        }
      });
      return (
        item.map((_item) => {
          return (<div key={uniqid()} className="form-field">{_item}</div>)
        })
      );
    }
  }

  // const continueCTA = isBI() ? tr.buttons.next_bi : tr.buttons.next;
  const submitReview = () => {
    isLoading = true;
    if (!_.isEmpty(fields)) {
      const newReview = review;
      newReview.questions = fields;
      setReview(newReview);
    }
    let token = "";
    if (
      localStorage.getItem("okta-token-storage") &&
      JSON.parse(localStorage.getItem("okta-token-storage")).idToken
    ) {
      token = JSON.parse(
        localStorage.getItem("okta-token-storage")
      ).idToken.claims.email;
    }
    let model;
    const flag = "di";
    const type = getServiceType();
    model = new SubmissionModel(review, flag, type, token);
    store.dispatch({type: "SAVE_AGENT_DATA", data: model});
    setShowNotification(true);
  }

  return (
    <div ref={fieldRef}>
      <div className='Questionnaires'>
        <p>{tr.digitalSubtitle}</p>
        {!questions.quesitonsLoaded && <Oval key={uniqid()} heigth="100" width="100" color='yellow' ariaLabel='loading'/>}
        {questions.quesitonsLoaded && renderItems(questions)}

      </div>
      <div className='action-box'>
        <Button
          className='save-questions'
          cta={trreview.buttons.submit}
          button_type="primary"
          handleClick={submitReview}
        />
      </div>
      {renderModal(isLoading, hasSuccess, "", "", "", showNotification, setShowNotification, notificationMsg, setReview)}
    </div>
  );
}

export default DigitalQuestionnaires;
